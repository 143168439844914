<!-- eslint-disable vue/max-attributes-per-line -->
<!-- eslint-disable vue/singleline-html-element-content-newline -->
<!-- filepath: /Users/schmucker/Desktop/GitPulls/LED_Corner/src/components/custom/wallSim.vue -->
<template>
  <div id="wallSimMainDiv">
    <canvas id="mainCanvas" ref="canvas" @contextmenu.prevent @mousedown="handleMouseDown" />
    
    <el-card v-if="selectedRect" id="rectPropertiesCard">
      <h2>Properties</h2>
      <div style="display: grid; grid-template-columns: 1fr 2fr; gap: 10px; align-items: center;">
        <label>Width:</label>
        <el-input v-model.number="selectedRect.width" @blur="updateRectProperty('width', Number(selectedRect.width).toFixed(1))" />
      
        <label>Height:</label>
        <el-input v-model.number="selectedRect.height" @blur="updateRectProperty('height', Number(selectedRect.height).toFixed(1))" />
      
        <label>Maintain Ratio</label>
        <el-checkbox v-model="maintainAspectRatio" />

        <label>X:</label>
        <el-input v-model.number="selectedRect.left" @blur="updateRectProperty('left', Number(selectedRect.left).toFixed(1))" />
      
        <label>Y:</label>
        <el-input v-model.number="selectedRect.top" @blur="updateRectProperty('top', Number(selectedRect.top).toFixed(1))" />
        
        <label>Amps:</label>
        <el-input v-model.number="selectedRect.amps" @blur="updateRectProperty('amps', Number(selectedRect.amps).toFixed(3))" />
      
        <label>Name:</label>
        <el-input v-model="selectedRect.wallName" @blur="updateRectProperty('wallName', selectedRect.wallName)" />
      
        <label>Selected: </label>
        <el-button @click="toggleLockForSelected">
          <i class="el-icon-lock" />
          Lock/Unlock
        </el-button>
      </div>
    </el-card>
    <el-card id="wallStatsCard">
      <div class="inline-section" style="width: 50%; display: inline-table;">
        <h2>Wall Statistics</h2>
        <el-select 
          v-model="selectedWall"
          placeholder="Select a wall"
          @change="handleWallSelectionChange"
        >
          <el-option
            v-for="rect in uniqueRects"
            :key="rect.wallName"
            :label="String('Name: ' + rect.wallName + ' (Tile W: ' + rect.width + ' H: ' + rect.height + ')')"
            :value="rect.wallName"
          />
        </el-select><br>
      </div>
      <div class="inline-section" style="width: 50%; display: inline-table;">
        <label class="article"> -- {{ selectedWall }} --</label><br>
        <label class="article"> Total Power: {{ totalAmps }} amps</label><br>
        <label class="article"> Total Weight: {{ totalWeight }} lbs</label><br><br>
      </div>
    </el-card>
    
    <!-- Wall Sim Inputs -->
    
    <el-card id="wallInput">
      <div class="inline-section">
        <h2>Panel Specs</h2>
        <p class="smallFont">
          Pixels Wide<br>
          <el-input v-model="pppWidth" type="form" value="" />
        </p>
        <p class="smallFont">
          Pixels Tall<br>
          <el-input v-model="pppHeight" type="form" value="" />
        </p>
        <p class="smallFont">
          Weight (pounds):<br>
          <el-input v-model="weight" type="form" value="" />
        </p>
        <p class="smallFont">
          Watts:<br>
          <el-input v-model="watts" type="form" value="" />
        </p>
      </div>
      <div class="inline-section">
        <h2>Wall Info</h2>
        <p class="smallFont">
          Name<br>
          <el-input v-model="wallName" type="form" value="" />
        </p>
        <p class="smallFont">
          Width (panels)<br>
          <el-input v-model="widthIn" type="form" value="" />
        </p>
        <p class="smallFont">
          Height (panels)<br>
          <el-input v-model="heightIn" type="form" value="" />
        </p>
        <el-select v-model="volts" value="208" name="Voltage" placeholder="Voltage"> 
          <el-option v-for="item in voltOptions" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </div>
      <div class="inline-section">
        <el-button class="submitButton" type="success" style="color:#3BBA9C" @click="drawWall()">
          <i class="el-icon-s-grid" />
          Create Wall
        </el-button><br>
      </div>
      <div class="inline-section">
        <el-button class="submitButton" @click="addText()">
          <i class="el-icon-edit" />
          Add Textbox
        </el-button><br>
      </div>
      <div class="inline-section">
        <h3>Switch</h3>
        <el-input v-model="switchPorts" style="width: 40%;" type="form" value="" />
        <el-button class="submitButton" style="white-space: normal;" @click="drawSwitch()">
          Add
        </el-button>
        <el-button @click="toggleLockForSelected">
          <i class="el-icon-lock" />
          Lock/Unlock <br> selected
        </el-button>
      </div><br>
      <h2>Coloring</h2>
      <div class="inline-section">
        <p class="smallFont" style="vertical-align: middle;">
          <el-color-picker v-model="color" color-format="rgb" :predefine="predefineColors" />
        </p>
        <el-button class="submitButton" @click="changeColor()">
          <i class="el-icon-brush" />
          Change Color
        </el-button><br>
      </div>
      <div class="inline-section">
        <h3>Tile Shading</h3>
        <el-button class="submitButton" icon="el-icon-bottom" @click="changeFill('topToBottom')" />
        <el-button class="submitButton" icon="el-icon-top" @click="changeFill('bottomToTop')" /><br>
        <el-button class="submitButton" icon="el-icon-back" @click="changeFill('rightToLeft')" />
        <el-button class="submitButton" icon="el-icon-right" @click="changeFill('leftToRight')" /><br>
      </div>
      <p>Cable Paint Mode</p>
      <el-switch v-model="cableDraw" active-color="#13ce66" inactive-color="#ff4949" />
      <p>Power Paint Mode</p>
      <el-switch v-model="powerPuck" active-color="#13ce66" inactive-color="#ff4949" />
      <p>Data Paint Mode</p>
      <el-switch v-model="dataPuck" active-color="#13ce66" inactive-color="#ff4949" /><br><br>
      <h2>Canvas Management</h2>
      <div class="inline-section">
        <el-button @click="downloadCanvas()">
          <i class="el-icon-download" />
          Export
        </el-button>
        <el-upload action="#" :before-upload="uploadCanvas" :auto-upload="true">
          <el-button>
            <template #trigger />
            <i class="el-icon-upload2" />
            Import
          </el-button>
        </el-upload>
      </div>
      <div class="inline-section">
        <el-button class="submitButton" style="color: rgb(255, 100, 100)" @click="clearSelected()">
          <i class="el-icon-error" />
          Clear Selected
        </el-button><br>
        <el-button id="calcButton2" class="submitButton" style="color: rgb(255, 100, 100)" @click="clearAll()">
          <i class="el-icon-delete" />
          Clear All
        </el-button><br>
      </div>
    </el-card>
  </div>
</template>

<script>
import { fabric } from "fabric";
import { mapState } from "vuex";

export default {
  name: "WallSimulator",
  data() {
    return {
      activeNames: ["1"],
      aspect: 1, 
      cableDraw: false,
      canvas: null,
      canvasJSON: '',
      color: 'rgb(0, 126, 126)',
      colorInput: '',
      dataPuck: false,
      dataPucks: [],
      heightIn: 10,
      maintainAspectRatio: false,
      pppHeight: 216,
      pppWidth: 384,
      powerPuck: false,
      powerPucks: [],
      predefineColors: [
        'rgb(255, 0, 0)',
        'rgb(0, 0, 255)',
        'rgb(0, 255, 0)',
        'rgb(255, 255, 0)',
        'rgb(0, 255, 255)',
        'rgb(255, 0, 255)',
      ],
      selectedWall: '',
      switchPorts: 12,
      value: "",
      voltOptions: [
        {
          value: 110,
          label: "110v",
        },
        {
          value: 208,
          label: "208v",
        },
        {
          value: 240,
          label: "240v",
        },
      ],
      volts: 208,
      wallName: 'Wall 1',
      watts: 30,
      weight: 18,
      widthIn: 10,
      actualValue: null,
      line: null,
      isDown: false,
      snap: 120,
      circles: [],
      selectedRect: null,
    };
  },
  computed: {
    ...mapState([
      'panelConst',
      'wallConst'
    ]),
    allRects() {
      if (this.canvas) {
        const objects = this.canvas.getObjects();
        const rects = objects.filter(rect => rect.type === 'rect');
        return rects;
      } else {
        return [];
      }
    },
    uniqueRects() {
      const names = this.allRects.map(rect => rect.wallName);
      return this.allRects.filter((rect, index) => names.indexOf(rect.wallName) === index);
    },
    totalAmps() {
      const selectedRectsByWall = this.allRects.filter(rect => rect.wallName === this.selectedWall);
      const totalAmps = selectedRectsByWall.reduce((sum, rect) => sum + (parseFloat(rect.amps) || 0), 0);
      return isNaN(totalAmps) ? '0.000' : totalAmps.toFixed(3);
    },
    totalWeight() {
      const selectedRectsByWall = this.allRects.filter(rect => rect.wallName === this.selectedWall);
      const totalWeight = selectedRectsByWall.reduce((sum, rect) => {
        return sum + Number(rect.weight || 0); // Ensure rect.weight is a number
      }, 0);
      return Math.round(Number(totalWeight));
    },
    pWidth: {
      get() {
        return Number(this.pppWidth);
      },
      set(val) {
        this.pppWidth = val;
      }
    },
    pHeight: {
      get() {
        return Number(this.pppHeight);
      },
      set(val) {
        this.pppHeight = val;
      }
    },
    heightOut: {
      get() {
        var actualValue = Number(this.heightIn);
        return actualValue;
      },
      set(val) {
        this.actualValue = val;
      }
    },
    widthOut: {
      get() {
        var actualValue = Number(this.widthIn);
        return actualValue;
      }
    },
    selectedRectProperties() {
      return this.selectedRect ? this.selectedRect.toObject() : {};
    },
  },

mounted: function () {
  this.initializeCanvas();    

  // Ensure custom properties are included in the JSON serialization
  fabric.Object.prototype.toObject = (function (toObject) {
    return function () {
      return fabric.util.object.extend(toObject.call(this), {
        amps: this.amps,
        wallName: this.wallName,
        weight: this.weight,
        isLocked: this.isLocked || false,
        text: this.text || '', // Ensure the text property is set
      });
    };
  })(fabric.Object.prototype.toObject);

  // Cabling stuff ---------------------------------------------------------------------------

    this.canvas.on('mouse:down', this.handleMouseDown);
    this.canvas.on('mouse:move', this.handleMouseMove);
    this.canvas.on('mouse:up', this.handleMouseUp);
    
    // Snapping --------------------------------------------------------------------------------
    
    this.canvas.on('object:moving', (e) => {
      this.snappingListener(e);
    });

    // Zoom ------------------------------------------------------------------------------------

    this.canvas.on("mouse:wheel", opt => {
      const delta = opt.e.deltaY;
      let zoom = this.canvas.getZoom();
      zoom = Math.max(0.01, Math.min(20, zoom * 0.999 ** delta));
      this.canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
    });

    // Copy and paste --------------------------------------------------------------------------
    
    document.addEventListener('keydown', (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
        this.copy();
      }
    });
    
    // Paste function
    
    document.addEventListener('keydown', (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
        this.paste();
      }
    });

    this.canvas.on('mouse:down', (opt) => {
      const target = this.canvas.findTarget(opt.e);
      if (target && target.type === 'rect') {
        this.selectedRect = target;
        this.selectedRect.set('amps', parseFloat(this.selectedRect.amps).toFixed(3)); // Round amps to 3 decimals
        this.aspect = target.aspectRatio; // Set the aspect ratio when a rectangle is selected
      } else {
        this.selectedRect = null;
      }
    });

    // Ensure custom properties are included in the JSON serialization
    fabric.Object.prototype.toObject = (function (toObject) {
      return function () {
        return fabric.util.object.extend(toObject.call(this), {
          amps: this.amps,
          wallName: this.wallName,
          weight: this.weight,
          isLocked: this.isLocked || false
        });
      };
    })(fabric.Object.prototype.toObject);

    // ...existing methods...
  },  
  beforeUnmount: function () {
    window.removeEventListener('resize', this.updateCanvasSize);
    document.removeEventListener('keydown', (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
        this.copy();
      }
    });
    document.removeEventListener('keydown', (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
        this.paste();
      }
    });
    this.canvas.off('mouse:down');
    this.canvas.off('mouse:move');
    this.canvas.off('mouse:up');
    this.canvas.off('mouse:wheel');
    this.canvas.off('object:moving');
    this.canvas.dispose();
  },
  methods: {

    // Canvas initialization ------------------------------------------------------------------

    initializeCanvas() {
      var canvas = new fabric.Canvas(this.$refs.canvas, {
        hoverCursor: "pointer",
        selection: true,
        selectionBorderColor: "green",
        backgroundColor: null,
        interactive: true,
      });
      this.canvas = canvas;
      this.updateCanvasSize();
      window.addEventListener('resize', this.updateCanvasSize);
      canvas.setZoom(0.1);
      canvas.renderAll();
    },

    // Canvas Size Adjustment ------------------------------------------------------------------

    updateCanvasSize() {
      const width = window.innerWidth;
      const height = window.innerHeight;
      this.canvas.setWidth(width);
      this.canvas.setHeight(height);
      this.canvas.calcOffset();
      this.canvas.renderAll();
    },
    snappingListener: function(e) {
      const movingObject = e.target;
      const baseSnapThreshold = 15;
      const snapThreshold = baseSnapThreshold / this.canvas.getZoom(); // Scale the snap threshold based on the zoom level
      let closestHorizontalSnap = null;
      let closestVerticalSnap = null;

      this.canvas.getObjects().forEach((obj) => {
        if (obj === movingObject) return; // Skip the moving object itself

        // Edges of the stationary object where the moving object could snap
        const objEdges = {
          left: obj.left,
          right: obj.left + obj.width,
          top: obj.top,
          bottom: obj.top + obj.height
        };

        // Potential snapping points for aligning corners
        const movingObjectCorners = {
          left: movingObject.left,
          right: movingObject.left + movingObject.width,
          top: movingObject.top,
          bottom: movingObject.top + movingObject.height
        };

        // Check for horizontal corner alignment
        Object.keys(objEdges).forEach((edgeKey) => {
          if (['left', 'right'].includes(edgeKey)) {
            // Horizontal alignment
            if (Math.abs(objEdges[edgeKey] - movingObjectCorners.left) < snapThreshold) {
              closestHorizontalSnap = objEdges[edgeKey];
            } else if (Math.abs(objEdges[edgeKey] - movingObjectCorners.right) < snapThreshold) {
              closestHorizontalSnap = objEdges[edgeKey] - movingObject.width;
            }
          } else {
            // Vertical alignment
            if (Math.abs(objEdges[edgeKey] - movingObjectCorners.top) < snapThreshold) {
              closestVerticalSnap = objEdges[edgeKey];
            } else if (Math.abs(objEdges[edgeKey] - movingObjectCorners.bottom) < snapThreshold) {
              closestVerticalSnap = objEdges[edgeKey] - movingObject.height;
            }
          }
        });
      });

      // Apply the closest snap positions for corner alignment
      if (closestHorizontalSnap !== null) {
        movingObject.set('left', closestHorizontalSnap);
      }
      if (closestVerticalSnap !== null) {
        movingObject.set('top', closestVerticalSnap);
      }

      movingObject.setCoords(); // Update the moving object's coordinates for accurate interaction
      this.canvas.renderAll(); // Re-render the canvas to apply changes
    },

    // Draw Switch ---------------------------------------------------------------------------

    drawSwitch: function () {
      const switchPorts = this.switchPorts; // User input for the number of switch ports
      const switchWidth = 1200; // Width of the large rectangle
      const switchHeight = 300; // Height of the large rectangle
      const switchRect = new fabric.Rect({
        fill: 'rgb(100, 100, 100)',
        opacity: 0.6,
        stroke: "white",
        strokeWidth: 1,
        width: switchWidth,
        height: switchHeight,
        left: (this.canvas.getWidth() - switchWidth) / 2, // Center the large rectangle horizontally
        top: (this.canvas.getHeight() - switchHeight) / 2, // Center the large rectangle vertically
        hasControls: false,
        renderOnAddRemove: false,
        wallName: 'Switch',
      });

      const rectWidth = switchWidth / switchPorts; // Width of each smaller rectangle
      const rectHeight = switchHeight; // Height of each smaller rectangle

      for (let i = 0; i < switchPorts; i++) {
        const rect = new fabric.Rect({
          fill: 'rgb(0, 150, 0)',
          opacity: 0.6,
          stroke: "white",
          strokeWidth: 1,
          width: rectWidth,
          height: rectHeight / 2,
          left: switchRect.left + (i * rectWidth), // Position each smaller rectangle horizontally
          top: switchRect.top + (rectHeight), // Align each smaller rectangle with the top edge of the large rectangle
          hasControls: false,
          renderOnAddRemove: false,
          wallName: 'Network Port'
        });
        this.canvas.add(rect);
        this.canvas.bringToFront(rect); 
      }

      const text = new fabric.Textbox('Switch', {
        left: switchRect.left,
        top: switchRect.top + switchHeight / 2,
        width: switchWidth,
        fontSize: 60, // Set the font size for larger text
        textAlign: 'center',
        fill: 'white',
        hasControls: true,
        renderOnAddRemove: true,
        scaleX: 1, // Ensure the scale is set to 1
        scaleY: 1, // Ensure the scale is set to 1
      });
      this.canvas.add(text);
      this.canvas.bringToFront(text);

      this.canvas.add(switchRect);
      this.canvas.renderAll();
      this.canvas.sendToBack(switchRect);
    },
    // Wall Drawings method --------------------------------------------------------------------

    drawWall: function () {
      let rects = [];
      const canvasWidth = this.canvas.getWidth();
      const canvasHeight = this.canvas.getHeight();
      const wallWidth = this.widthOut * this.pppWidth;
      const wallHeight = this.heightOut * this.pppHeight;
      const left = (canvasWidth - wallWidth) / 2;
      const top = (canvasHeight - wallHeight) / 2;

      for (var j = 0; j < this.heightOut; j++) {
        for (var i = 0; i < this.widthOut; i++) {
          const rect = new fabric.Rect({
            fill: this.color,
            width: this.pWidth,
            height: this.pHeight,
            opacity: 0.6,
            stroke: "white",
            strokeWidth: 3,
            left: left + this.pppWidth * i,
            top: top + this.pppHeight * j,
            hasControls: false,
            renderOnAddRemove: false,
            amps: this.watts / this.volts,
            wallName: this.wallName,
            weight: this.weight,
            aspectRatio: this.pWidth / this.pHeight, // Store aspect ratio
          });
          rects.push(rect);
        }
      }
      this.canvas.add(...rects);

      // Calculate zoom level to show the whole wall
      const zoomX = canvasWidth / wallWidth;
      const zoomY = canvasHeight / wallHeight;
      const zoom = Math.min(zoomX, zoomY) * 0.5; // Adjust zoom level for better visibility

      this.canvas.setViewportTransform([zoom, 0, 0, zoom, canvasWidth / 2.4, canvasHeight / 2.4]); // Apply zoom level and center the viewport
      this.canvas.renderAll();
      rects = [];
    },

    // Tile shading stuff ----------------------------------------------------------------------

    changeFill: function(direction) {
      const selectedRects = this.canvas.getActiveObjects();
      const maxHeight = this.canvas.getHeight();
      const colorIn = this.color.replace("rgb", "").replace(")", "").replace("(", "")
      const colorValues = colorIn ? colorIn.split(',').map(value => (value.trim())) : null;
      const maxWidth = this.canvas.getWidth();

      if (colorValues.length !== 3 || colorValues.some(value => isNaN(value) || value < 0 || value > 255)) {
        alert('Invalid RGB format. Please enter valid RGB values separated by commas (e.g., 255, 0, 0)');
        return;
      }
      const startColor = colorValues;
      const endColor = [10, 10, 10];

      let minTop = maxHeight;
      let maxBottom = 0;
      let minLeft = maxWidth;
      let maxRight = 0;

      selectedRects.forEach((rect) => {
        if (rect.top < minTop) {
          minTop = rect.top;
        }
        if (rect.top + rect.height > maxBottom) {
          maxBottom = rect.top + rect.height;
        }
        if (rect.left < minLeft) {
          minLeft = rect.left;
        }
        if (rect.left + rect.width > maxRight) {
          maxRight = rect.left + rect.width;
        }
      });

      selectedRects.forEach((rect) => {
        let shadingDirection;
        if (direction === 'bottomToTop') {
          shadingDirection = 1 - (rect.top + rect.height - minTop) / (maxBottom - minTop);
        } else if (direction === 'rightToLeft') {
          shadingDirection = 1 - (rect.left - minLeft) / (maxRight - minLeft);
        } else if (direction === 'leftToRight') {
          shadingDirection = (rect.left + rect.width - minLeft) / (maxRight - minLeft);
        } else { // Default to top to bottom
          shadingDirection = (rect.top - minTop) / (maxBottom - minTop);
        }

        const interpolatedColor = startColor.map((startValue, i) => {
          const endValue = endColor[i];
          let value = startValue * (1 - shadingDirection) + endValue * shadingDirection;
          value = Math.round(value); // Round the interpolated value
          return value;
        });
        const fill = `rgba(${interpolatedColor[0]}, ${interpolatedColor[1]}, ${interpolatedColor[2]}, 1)`;
        rect.set('fill', fill);
      });
      this.canvas.requestRenderAll();
    },    

    // Copy and paste --------------------------------------------------------------------------

    copy: function () {
      this.clipboard = [];
      this.canvas.getActiveObjects().forEach((obj) => {
        const cloned = obj.toObject(['wallName', 'amps', 'weight']); // Include custom properties
        this.clipboard.push(cloned);
      });
    },
    paste: function () {
      this.canvas.discardActiveObject();
      const newObjects = [];
      fabric.util.enlivenObjects(this.clipboard, (objects) => {
        objects.forEach((obj) => {
          // Set custom properties
          obj.set({
            wallName: obj.wallName,
            amps: obj.amps,
            weight: obj.weight,
          });
          this.canvas.add(obj);
          newObjects.push(obj);
        });
        const group = new fabric.ActiveSelection(newObjects, {
          canvas: this.canvas,
        });
        this.canvas.setActiveObject(group);
        this.canvas.requestRenderAll();
      });
    },    

    // Change color ----------------------------------------------------------------------------

    changeColor: function () {
      this.canvas.getActiveObjects().forEach((obj) => {
        obj.set('fill', this.color)
      });
      this.canvas.renderAll();
    },

    // Textbox creation ------------------------------------------------------------------------

    addText: function () {
      var textbox = new fabric.Textbox("Enter Text", {
        fill: "rgb(150,150,150)",
        width: 200,
        height: 200,
        scaleX: 5,
        scaleY: 5,
        fontFamily: 'Arial', // Change this to the desired font
        text: "Enter Text" // Ensure the text property is set
      });
      this.canvas.add(textbox);
      this.canvas.renderAll();
    },
      // Download Canvas as JSON -----------------------------------------------------------------

    downloadCanvas: function () {
      const json = JSON.stringify(this.canvas.toJSON(['text', 'fill', 'fontSize', 'fontFamily', 'fontWeight', 'fontStyle', 'textAlign', 'lineHeight', 'underline', 'overline', 'linethrough']));
      const blob = new Blob([json], {type: 'application/json'});
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'canvas.json';
      link.click();
    },
    // Load Canvas -----------------------------------------------------------------------------
    
    uploadCanvas(file) {
      if (file.type !== 'application/json') {
        this.$message.error('File is not a JSON file');
        return false;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const json = JSON.parse(e.target.result);
        this.canvas.loadFromJSON(json, () => {
          this.canvas.renderAll.bind(this.canvas)();
          this.updateWallStatistics(); // Update wall statistics after loading the canvas
        }, (o, object) => {
          // Ensure custom properties are included
          if (object.type === 'rect') {
            object.set({
              amps: o.amps,
              wallName: o.wallName,
              weight: o.weight,
              isLocked: o.isLocked || false,
              lockMovementX: o.isLocked || false,
              lockMovementY: o.isLocked || false,
              lockRotation: o.isLocked || false,
              lockScalingX: o.isLocked || false,
              lockScalingY: o.isLocked || false,
              selectable: !o.isLocked
            });
          } else if (object.type === 'textbox' || object.type === 'text') {
            object.set({
              text: o.text || '', // Ensure the text property is set
              fill: o.fill || 'black',
              fontSize: o.fontSize || 16,
              fontFamily: o.fontFamily || 'Arial',
              fontWeight: o.fontWeight || 'normal',
              fontStyle: o.fontStyle || 'normal',
              textAlign: o.textAlign || 'left',
              lineHeight: o.lineHeight || 1.16,
              underline: o.underline || false,
              overline: o.overline || false,
              linethrough: o.linethrough || false,
            });
          }
        });
      };
      reader.readAsText(file);
      return false; // prevent the file from being uploaded to the server
    },
    updateWallStatistics() {
    // Update the selected wall and other relevant properties
    if (this.allRects.length > 0) {
      this.selectedWall = this.allRects[0].wallName;
    } else {
      this.selectedWall = '';
    }
  },
    
    // Clearing objects ------------------------------------------------------------------------

    clearSelected: function () {
      this.canvas.getActiveObjects().forEach((obj) => {
        this.canvas.remove(obj);
      });
      this.canvas.discardActiveObject().renderAll();
    },
    clearAll: function () {
      this.$confirm(
        "Are you sure you want to delete everything?",
        "Clear Canvas",
        {
          distinguishCancelAndClose: true,
          confirmButtonText: "Clear Canvas",
          cancelButtonText: "Cancel",
        }
      )
        .then(() => {
          this.$message({
            type: "info",
            message: "Canvas Cleared!",
          });
          this.canvas.clear();
          this.powerPucks = [];
          this.dataPucks = [];
        })
        .catch((action) => {
          this.$message({
            type: "info",
            message:
              action === "cancel" ? "Your work is safe..." : "Staying on track",
          });
        });
    },
    handleWallSelectionChange() {
      this.selectedRect = null; // Clear the selected rectangle when a new wall is selected
      this.$nextTick(() => {
        this.canvas.discardActiveObject().renderAll(); // Ensure the canvas is updated
      });
    },

    handleMouseDown(options) {
      this.isDown = true;
      const pointer = this.canvas.getPointer(options.e);
      const points = [pointer.x, pointer.y, pointer.x, pointer.y];

      if (this.cableDraw) {
        this.line = new fabric.Line(points, {
          strokeWidth: 3,
          fill: 'rgb(0, 50, 250)',
          stroke: 'rgb(0, 50, 250)',
          originX: 'center',
          originY: 'center',
          selectable: true,
        });
        this.canvas.add(this.line);
      }
      if (this.cableDraw || this.powerPuck || this.dataPuck) {
        this.canvas.selection = false;
        this.lockAllTiles();
      }

      const target = this.canvas.findTarget(options.e);
      if (target && target.type === 'rect') {
        this.selectedRect = target;
        this.selectedRect.set('amps', parseFloat(this.selectedRect.amps).toFixed(3)); // Round amps to 3 decimals
        this.aspect = target.aspectRatio; // Set the aspect ratio when a rectangle is selected
      } else {
        this.selectedRect = null;
      }
    },
    handleMouseMove(options) {
      if (!this.isDown) return;
      const pointer = this.canvas.getPointer(options.e);
      const targetRect = this.getTargetRect(pointer);

      if (this.powerPuck) {
        this.handlePowerPuck(targetRect);
      } else if (this.dataPuck) {
        this.handleDataPuck(targetRect);
      } else if (this.cableDraw) {
        this.handleCableDraw(pointer, targetRect);
      }
    },
    handleMouseUp() {
      this.isDown = false;
      this.line = null;
      this.unlockAllTiles();
      this.canvas.selection = true;
      this.canvas.renderAll();
    },
    lockAllTiles() {
      const objects = this.canvas.getObjects();
      for (let obj of objects) {
        if (!obj.isLocked) {
          obj.lockMovementX = true;
          obj.lockMovementY = true;
          obj.lockRotation = true;
          obj.lockScaling = true;
        }
      }
    },
    unlockAllTiles() {
      const objects = this.canvas.getObjects();
      for (let obj of objects) {
        if (!obj.isLocked) {
          obj.lockMovementX = false;
          obj.lockMovementY = false;
          obj.lockRotation = false;
          obj.lockScaling = false;
          obj.selectable = true;
        }
      }
    },
    toggleLockForSelected() {
      const selectedObjects = this.canvas.getActiveObjects();
      if (selectedObjects.length > 0) {
        selectedObjects.forEach(obj => {
          const isLocked = obj.isLocked || false;
          obj.set({
            lockMovementX: !isLocked,
            lockMovementY: !isLocked,
            lockRotation: !isLocked,
            lockScalingX: !isLocked,
            lockScalingY: !isLocked,
            selectable: !isLocked,
            isLocked: !isLocked
          });
        });
        this.canvas.discardActiveObject();
        this.canvas.renderAll();
      }
    },
    getTargetRect(pointer) {
      const objects = this.canvas.getObjects('rect');
      for (let obj of objects) {
        const objCenter = obj.getCenterPoint();
        if (Math.abs(pointer.x - objCenter.x) < this.snap && Math.abs(pointer.y - objCenter.y) < this.snap) {
          pointer.x = objCenter.x;
          pointer.y = objCenter.y;
          return obj;
        }
      }
      return null;
    },
    handlePowerPuck(targetRect) {
      if (targetRect) {
        const targetPowerPuck = this.powerPucks.find(powerPuck => {
          return powerPuck.originalLeft === targetRect.left && powerPuck.originalTop === targetRect.top;
        });

        if (!targetPowerPuck) {
          const group = new fabric.Group([], {
            left: targetRect.left,
            top: targetRect.top,
            selectable: true,
          });

          const smallRect = new fabric.Rect({
            left: targetRect.left + targetRect.width * 0.15,
            top: targetRect.top + 10,
            width: targetRect.width * 0.65,
            height: targetRect.height * 0.3,
            fill: 'rgb(50, 150, 0)',
            stroke: 'black',
            strokeWidth: 6,
            selectable: true,
          });

          const icon = new fabric.Text('Power', {
            left: smallRect.left + smallRect.width / 2,
            top: smallRect.top + smallRect.height / 2,
            fontSize: 50,
            fontFamily: 'Arial',
            originX: 'center',
            originY: 'center',
            selectable: true,
          });

          this.powerPucks.push({
            originalLeft: targetRect.left,
            originalTop: targetRect.top,
            group: group,
          });

          group.addWithUpdate(smallRect);
          group.addWithUpdate(icon);
          this.canvas.add(group);
          this.canvas.bringToFront(group);
        }
      }
    },
    handleDataPuck(targetRect) {
      if (targetRect) {
        const targetDataPuck = this.dataPucks.find(dataPuck => {
          return dataPuck.originalLeft === targetRect.left && dataPuck.originalTop === targetRect.top;
        });

        if (!targetDataPuck) {
          const group = new fabric.Group([], {
            left: targetRect.left,
            top: targetRect.top,
            selectable: true,
          });

          const smallRect = new fabric.Rect({
            left: targetRect.left + targetRect.width * 0.15,
            top: targetRect.top + targetRect.height / 1.5,
            width: targetRect.width * 0.6,
            height: targetRect.height * 0.3,
            fill: 'blue',
            stroke: 'black',
            strokeWidth: 3,
            selectable: true,
          });

          const icon = new fabric.Text('Data', {
            left: smallRect.left + smallRect.width / 2,
            top: smallRect.top + smallRect.height / 2,
            fontSize: 50,
            fill: 'white',
            fontFamily: 'Arial',
            originX: 'center',
            originY: 'center',
            selectable: true,
          });

          this.dataPucks.push({
            originalLeft: targetRect.left,
            originalTop: targetRect.top,
            group: group,
          });

          group.addWithUpdate(smallRect);
          group.addWithUpdate(icon);
          this.canvas.add(group);
          this.canvas.bringToFront(group);
        }
      }
    },
    handleCableDraw(pointer, targetRect) {
      if (this.line) {
        this.line.set({ x2: pointer.x, y2: pointer.y });
        this.canvas.renderAll();
      }

      if (targetRect) {
        const targetCircle = this.circles.find(circle => {
          const circleCenter = circle.getCenterPoint();
          const targetRectCenter = targetRect.getCenterPoint();
          return circleCenter.x === targetRectCenter.x && circleCenter.y === targetRectCenter.y;
        });

        if (!targetCircle && !this.isCircleWithinRange(pointer.x, pointer.y)) {
          const circle = new fabric.Circle({
            left: targetRect.getCenterPoint().x - 20,
            top: targetRect.getCenterPoint().y - 20,
            radius: 20,
            fill: this.color,
            stroke: 'black',
            strokeWidth: 3,
            selectable: true,
          });
          this.canvas.add(circle);
          this.canvas.bringToFront(circle);
          this.circles.push(circle);
        }

        const points = [pointer.x, pointer.y, pointer.x, pointer.y];
        this.line = new fabric.Line(points, {
          strokeWidth: 10,
          fill: 'rgb(0, 50, 250)',
          stroke: 'rgb(0, 50, 250)',
          originX: 'center',
          originY: 'center',
          selectable: true,
        });
        this.canvas.add(this.line);
        this.canvas.sendBackwards(this.line, true);
      }
    },
    isCircleWithinRange(x, y) {
      for (let circle of this.circles) {
        const circleCenter = circle.getCenterPoint();
        if (Math.abs(x - circleCenter.x) < this.snap && Math.abs(y - circleCenter.y) < this.snap) {
          return true;
        }
      }
      return false;
    },
    updateRectProperty(property, value) {
      if (this.selectedRect) {
        if (property === 'wallName') {
          this.selectedRect.set(property, value);
        } else {
          if (this.maintainAspectRatio && (property === 'width' || property === 'height')) {
            const aspect = this.selectedRect.aspectRatio;
            if (property === 'width') {
              this.selectedRect.set('height', value / aspect);
            } else {
              this.selectedRect.set('width', value * aspect);
            }
          }
          if (property === 'amps') {
            value = parseFloat(value).toFixed(3);
          }
          this.selectedRect.set(property, parseFloat(value));
        }
        this.selectedRect.setCoords(); // Update the object's coordinates
        this.canvas.renderAll();
      }
    },
  }
}
      
</script>

<style lang="scss" scoped>
@import '../../styles/variables';

#rectPropertiesCard {
  position: fixed;
  top: 30%;
  left: 1%;
  max-height: 50%;
  max-width: 250px;
  background-color: $secondary-color;
  border: 2px outset $border-color;
  border-radius: $border-radius;
  z-index: 1000;
  overflow: auto;
  padding: 15px;
  transition: transform 0.3s, box-shadow 0.3s;
}
#mainCanvas {
  overflow: hidden;
}
#wallStatsCard {
  position: fixed;
  margin: 0;
  background-color: #212121;
  width: 40%;  /* Adjusted width */
  left: 50%;
  bottom: 1%;
  transform: translateX(-50%);
  border-style: outset;
  border-radius: 1em;
  border-width: 2px;
  border-color: rgb(55, 55, 55);
  overflow: auto;
}
#wallInput {
  position: fixed;
  background-color: #212121;
  right: 1%;
  top: 10%;
  height: 80%; /* Adjusted height */
  max-width: 150px;
  border-style: outset;
  border-width: 2px;
  border-radius: 1em;
  border-color: rgb(55, 55, 55);
  z-index: 1000;
  overflow: auto;
}

@media (max-width: 800px) {
  h2 {
    font-size: 10pt;
  }
  #mainCanvas {
    overflow: hidden;
    margin: auto;
    width: 100vw;  /* Adjusted width */
    height: 100vh; /* Adjusted height */
  }
  .canvas-container {
    top: 0; /* Adjusted top */
    overflow: hidden;
    margin: auto;
    width: 100vw;  /* Adjusted width */
    height: 100vh; /* Adjusted height */
  }
  .inline-section {
    display: inline-table;
    width: 50%;
  }
  .upper-canvas, .lower-canvas {
    overflow: hidden;
    margin: auto;
    width: 100vw;  /* Adjusted width */
    height: 100vh; /* Adjusted height */
  }
  #wallInput {
    position: absolute;
    left: 0;
    top: 80%;
    background-color: rgb(10,10,10);
    width: 375px;  /* Adjusted width */
    height: 15%;
    max-width: max-content;
  }
  #wallInput .submitButton {
    width: 100%;  /* Make all buttons the same width */
  }
  #wallStatsCard {
    position: absolute;
    width: max-content;
    top: 66%;
    height: 12%;
  }
}
@media (min-width: 801px) {
  #mainCanvas {
    width: 4000px;
    height: 3000px;
  }
}
</style>