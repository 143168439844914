<template>
  <el-collapse>
    <el-menu
      default-active="5"
      :router="true"
      class="el-menu-horizontal"
      background-color="rgb(25,25,25)"
      text-color="#fff"
      active-text-color="#3BBA9C"
      mode="horizontal"
    >
      <el-menu-item 
        index="1"
        :route="{name:'wallSimulator'}"
      >
        <i class="el-icon-s-grid" />
        <span>Wall Sim</span>
      </el-menu-item>
      <el-menu-item 
        index="2"
        :route="{name:'calculatorCorner'}"
      >
        <i class="el-icon-set-up" />
        <span>Calculators</span>
      </el-menu-item>
      <el-menu-item
        index="3"
        :route="{name:'parserCorner'}"
      >
        <i class="el-icon-data-analysis" />
        <span>Parser</span>
      </el-menu-item>
      <el-menu-item
        index="4"
        :route="{name:'learningCorner'}"
      >
        <i class="el-icon-reading" />
        <span>Learning</span>
      </el-menu-item>
      <el-menu-item 
        index="5"
        :route="{name:'aboutUs'}"
      >
        <i class="el-icon-info" />
        <span>About</span>
      </el-menu-item>
    </el-menu>
    <img
      src="../assets/ledcornerlogo.png"
      class="logo"
    >
  </el-collapse>
</template>
<script>
  export default {
  }
</script>

<style lang="scss" scoped>
body {
  height: 100%;
  overflow: hidden;
}
.el-collapse {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  top: 0;
  overflow: hidden;
  max-width: 100%;
  padding-bottom: 0;
  border-radius: 0;
  z-index: 1000;

}
.el-menu-item {
  background-color: #212121 !important;
}
.el-menu.el-menu--horizontal{
  border-right: none;
  border-bottom: none;
}
.el-menu--horizontal>.el-menu-item {
  padding-left: 10px;
}
@media only screen and (max-width: 800px) {
  .el-collapse {
    margin: auto;
  }
  .el-menu-item * {
    font-size: 6pt;
  }
  .el-menu-item {
    padding: 0 10px;
  }
  .el-menu--horizontal>.el-menu-item {
    padding-left: 5px;
  }
}
</style>
